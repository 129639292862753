import React from 'react';
import { Carousel } from 'react-bootstrap';
import { Link } from "react-router-dom";
import '../Css/card.css';
import img6 from '../images/LFC_Thane.png';
import img4 from '../images/LWP_Thane.png';
import img12 from '../images/republicday.jpg';



function SpecialofferComponent() {
  const userdetails = JSON.parse(localStorage.getItem('userdata'));
  const labcode = userdetails.user.labids[0].labCode;
  let images = [];

  if (labcode === 'lab05') {
    images = [img4, img6, img12];
  } else if (labcode === 'lab06') {
    images = [img4, img6, img12];
  } else if (labcode === 'lab08') {
    images = [img4, img6, img12];
  }

  return (
    <div className="special-offer-section container">
      <h2 className="special-offer-title">Offers</h2>
      <Carousel className="special-offer-images">
        {images.map((image, index) => (
          <Carousel.Item key={index}>
            <img
              className="d-block w-100"
              src={image}
              alt={`Independence Offer ${index + 1}`}
            />
          </Carousel.Item>
        ))}
      </Carousel>
      <Link to="/book">
        <button className='btn mt-2' style={{background:"#f9a94a", color:"white"}}>
          Book Now
        </button>
      </Link>
    </div>
  );
}

export default SpecialofferComponent;
